import React from "react";

export default function PortfolioSplit({ section = {} }) {
  const { content = [] } = section;
  return (
    <section className="wrapper">
      <div className="contact-section-four mt-200 lg-mt-120">
        <div className="case-study-five full-width border-top pb-0">
          <div className="container-fluid">
            <div className="mixitUp-container clearfix">
              {content.map((c) => (
                <div className="mix" key={c.id}>
                  <div className="case-block-one position-relative mt-45 lg-mt-30">
                    <img src={c.image.url} alt="" className="img-meta w-100" />
                    <div className="hover-content d-flex flex-column">
                      <div className="icon d-flex align-items-center justify-content-center">
                        <img src="images/icon/icon_11.svg" alt="" />
                      </div>
                      <blockquote>{c.paragraph}</blockquote>
                      <a href="portfolio-detailsV3.html" className="w-100 d-flex align-items-center justify-content-between mt-auto">
                        <span className="web">www.heloshape.com</span>
                        <span className="arrow">
                          <i className="bi bi-chevron-right"></i>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
