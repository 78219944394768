import React from "react";

export default function CodeResources({ section }) {
  return (
    <>
      <div className="blog-section-two pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 m-auto">
              <div className="row align-items-center mb-50 lg-mb-20">
                <div className="col-sm-6">
                  <div className="title-style-two">
                    <h3 className="title">{section.title}</h3>
                  </div>
                </div>
              </div>
              <div className="row gx-xl-5">
                <p>{section.description}</p>
              </div>

              <div className="row gx-xl-5">
                {section.resources.map((r) => (
                  <div className="col-md-4" key={r.title}>
                    <article className="blog-meta-two mt-40">
                      <div className="img-meta" style={{ border: "1px solid #dddddd", borderRadius: "8px", height: "300px", width: "300px", padding: "22px" }}>
                        <img src={r.image.url} alt="" />
                      </div>
                      <div className="post-meta">
                        <div className="date">{new Date(r.lastUpdated).toDateString().substring(4)}</div>
                        <a href={r.repositoryUrl} className="title" target="_blank" rel="noreferrer">
                          {r.title}
                        </a>
                        {r.repositoryUrl || r.downloadUrl ? (
                          <div className="d-flex">
                            <a href={r.repositoryUrl} className="theme-btn-three me-3" target="_blank" rel="noreferrer">
                              <i className="fab fa-bitbucket me-1"></i>Repository
                            </a>
                            <a href={r.downloadUrl} className="theme-btn-three" target="_blank" rel="noreferrer">
                              <i className="fas fa-download me-1"></i>Download
                            </a>
                          </div>
                        ) : (
                          <div className="text-secondary text-uppercase">Coming soon</div>
                        )}
                        <p className="text-muted mt-3">{r.description}</p>
                      </div>
                    </article>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
