import React from "react";
import icon_33 from "../images/icon/icon_33.svg";

export default function Split({ section = {} }) {
  const Words = () => (
    <div className="col-xxl-6 col-xl-7 col-lg-6">
      <div className="feedback-wrapper ps-xxl-5 pt-0">
        <div className="icon d-flex align-items-center justify-content-center">
          <img src={icon_33} alt="" />
        </div>
        <div className="title-style-one">
          <h2 className="title">
            {section.title} <span>{section.titleUnderline}</span> {section.titleTail}
          </h2>
        </div>
        <p className="text-lg preWrap pt-4 pb-3"> {section.paragraph}</p>
        {section.reference && (
          <>
            {section.reference.startsWith("http") ? (
              <a href={section.reference} target="_blank" rel="noreferrer">
                <small className="quote"> - {section.reference}</small>
              </a>
            ) : (
              <small className="quote"> - {section.reference}</small>
            )}
          </>
        )}
      </div>
    </div>
  );

  const Image = () => (
    <div className="col-xxl-6 col-xl-5 col-lg-6 mb-30">
      <div className="video-img-box">
        <img src={section.image.url} alt="" />
      </div>
    </div>
  );

  return (
    <>
      <div className="border-top mt-50 pt-50 md-mt-100 md-pt-100 lg-mt-150 lg-pt-150">
        <div className="container">
          <div className="row align-items-center">
            {section.alignleft ? (
              <>
                <Image />
                <Words />
              </>
            ) : (
              <>
                <Words />
                <Image />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
