import React, { useState } from "react";
import VideoModal from "./VideoModal";
import ActionLink from "./ActionLink";

export default function VideoResources({ section }) {
  const [videoId, setVideoId] = useState();
  return (
    <>
      {videoId && <VideoModal videoId={videoId} close={() => setVideoId(false)} />}
      <div className="blog-section-two pt-100">
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 m-auto">
              <div className="row align-items-center mb-50 lg-mb-20">
                <div className="col-sm-6">
                  <div className="title-style-two">
                    <h3 className="title">{section.title}</h3>
                  </div>
                </div>
              </div>
              <div className="row gx-xl-5">
                <p>{section.description}</p>
              </div>

              <div className="row gx-xl-5">
                {section.resources.map((r) => (
                  <div className="col-md-4" key={r.title}>
                    <ActionLink onClick={() => setVideoId(r.videoId)}>
                      <article className="blog-meta-two mt-40">
                        <div className="img-meta" style={{ border: "1px solid #dddddd", borderRadius: "8px", padding: "22px" }}>
                          <img src={r.image?.url} alt="" />
                        </div>
                        <div className="post-meta">
                          <div className="date">{new Date(r.lastUpdated).toDateString().substring(4)}</div>
                          <a href={r.repositoryUrl} className="title" target="_blank" rel="noreferrer">
                            {r.title}
                          </a>
                          <p className="text-muted mt-3">{r.description}</p>
                        </div>
                      </article>
                    </ActionLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
