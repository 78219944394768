import React from "react";
import shape_53 from "../images/shape/shape_53.svg";

export default function BoldStatement({ section }) {
  return (
    <div className="vcamp-feature-section-nine pt-150 lg-pt-120 border-top">
      <div className="container">
        <div className="title-style-seven text-center pb-60 lg-pb-20">
          <h2 className="title">
            {section.title}{" "}
            <span>
              {section.subTitle} <img src={shape_53} alt="" />
            </span>
          </h2>
        </div>
      </div>
    </div>
  );
}
