import React from "react";
import shape_53 from "../images/shape/shape_53.svg";

export default function BoldStatement({ section }) {
  return (
    <div className="vcamp-feature-section-nine pt-130 mt-130">
      <div className="text-center text-lg">
        <del>{section.lead}</del>
      </div>
      <div className="container">
        <div className="title-style-seven text-center pb-60 lg-pb-20">
          <h2 className="title">
            {section.title}{" "}
            <span className="mt-4">
              {section.subTitle} <img src={shape_53} alt="" />
            </span>
          </h2>
        </div>
        <div className="pt-100 pb-100">
          <div className="preWrap">{section.description}</div>
        </div>
      </div>
    </div>
  );
}
