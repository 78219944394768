import React from "react";

export default function HeadingBlock({ section }) {
  return (
    <div className="vcamp-text-block-one pt-180 md-pt-130">
      <div className="container">
        <div className="border-bottom pb-75 md-pb-50">
          <div className="row">
            <div className="col-12 m-auto">
              <div className="text-wrapper text-center ps-0">
                <div className="title-style-one">
                  <h3 className="title">{section.title}</h3>
                </div>
                <p className="text-lg pt-35 lg-pt-30 preWrap">{section.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
