import React, { useEffect } from "react";
import Preloader from "./Preloader";
import Sections from "./Sections";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const isProd = process.env.REACT_APP_IS_PROD === "true";

export default function Guide() {
  const [site, setSite] = React.useState();
  const query = useQuery();

  const siteInstance = isProd ? "" : query.get("instanceId") ? "/instance/" + query.get("instanceId") : "";
  const hhEndpoint = `https://api.headlesshost.com/sites/${process.env.REACT_APP_HH_SITE_ID}${siteInstance}/guide`;

  useEffect(() => {
    async function fetchSite() {
      try {
        const res = await fetch(hhEndpoint);
        return await res.json();
      } catch (error) {
        console.log(error);
      }
    }
    if (!site) {
      fetchSite()
        .then((resp) => {
          setSite(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [hhEndpoint, site]);

  if (!site) return <Preloader />;

  const { content = {} } = site;
  const { sections = [] } = content;

  return sections.map((s) => {
    return (
      <div style={{ borderBottom: "3px dashed #ccc" }}>
        <Sections sections={[s]} />
      </div>
    );
  });
}
