import React from "react";
import Sections from "./Sections";
import BlogSidebar from "./BlogSidebar";

export default function News({ sections = [], globals = {} }) {
  const newsSections = [...sections];
  const { blogSidebar } = globals;
  return (
    <>
      <Sections sections={[newsSections[0]]} globals={globals} />
      <div className="blog-section-five mt-160 mb-150 lg-mt-100 lg-mb-120">
        <div className="container">
          <div className="row gx-xl-5">
            <div className="col-lg-8">
              <Sections sections={newsSections.splice(1)} globals={globals} />
            </div>
            <BlogSidebar section={blogSidebar} />
          </div>
        </div>
      </div>
    </>
  );
}
