import React from "react";
import shape_53 from "../images/shape/shape_53.svg";

export default function AnimatedFull({ section }) {
  return (
    <section className="wrapper ">
      <div className="hero-bannerfive mt-200 md-mt-150">
        <div className="container">
          <div className="title-style-seven text-center pb-60 lg-pb-20">
            <h2 className="title">
              {section.title}
              <span className="mt-4">
                {section.subTitle} <img src={shape_53} alt="" />
              </span>
            </h2>
          </div>
          <div className="mt-100">
            <img src={section.animated.url} alt="Headlesshost" className="rounded-5 mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
}
