import $ from "jquery";

export function init() {
  // ------------------------ Navigation Scroll
  $(window).on("scroll", function () {
    var sticky = $(".sticky-menu"),
      scroll = $(window).scrollTop();
    if (scroll >= 100) sticky.addClass("fixed");
    else sticky.removeClass("fixed");
  });

  // -------------------- From Bottom to Top Button
  $(window).on("scroll", function () {
    if ($(this).scrollTop() > 200) {
      $(".scroll-top").fadeIn();
    } else {
      $(".scroll-top").fadeOut();
    }
  });

  //---------------------- Click event to scroll to top
  $(".scroll-top").on("click", function () {
    $("html, body").animate({ scrollTop: 0 });
    return false;
  });

  // ------------------------ Full Page Setup One
  if ($("#page-scroll-container-one").length) {
    $("#page-scroll-container-one").pagepiling({
      scrollingSpeed: 50,
      loopTop: true,
      loopBottom: true,
    });
  }

  // ------------------------ Full Page Setup Two
  if ($("#page-scroll-container-two").length) {
    $("#page-scroll-container-two").pagepiling({
      scrollingSpeed: 50,
      loopTop: true,
      loopBottom: true,
    });
  }

  // ----------------------- SVG convert Function
  $("img.svg").each(function () {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(
      imgURL,
      function (data) {
        // Get the SVG tag, ignore the rest
        var $svg = $(data).find("svg");

        // Add replaced image's ID to the new SVG
        if (typeof imgID !== "undefined") {
          $svg = $svg.attr("id", imgID);
        }
        // Add replaced image's classes to the new SVG
        if (typeof imgClass !== "undefined") {
          $svg = $svg.attr("class", imgClass + " replaced-svg");
        }

        // Remove any invalid XML tags as per http://validator.w3.org
        $svg = $svg.removeAttr("xmlns:a");

        // Check if the viewport is set, else we gonna set it if we can.
        if (!$svg.attr("viewBox") && $svg.attr("height") && $svg.attr("width")) {
          $svg.attr("viewBox", "0 0 " + $svg.attr("height") + " " + $svg.attr("width"));
        }

        // Replace image with new SVG
        $img.replaceWith($svg);
      },
      "xml"
    );
  });

  $(window).on("load", function () {
    // makes sure the whole site is loaded

    // -------------------- Site Preloader
    $("#ctn-preloader").fadeOut(); // will first fade out the loading animation
    $("#preloader").delay(350).fadeOut("slow"); // will fade out the white DIV that covers the website.
    $("body").delay(350).css({ overflow: "visible" });

    // ----------------------------- isotop gallery
    if ($("#isotop-gallery-wrapper").length) {
      var $grid = $("#isotop-gallery-wrapper").isotope({
        // options
        itemSelector: ".isotop-item",
        percentPosition: true,
        masonry: {
          // use element for option
          columnWidth: ".grid-sizer",
        },
      });

      // filter items on button click
      $(".isotop-menu-wrapper").on("click", "li", function () {
        var filterValue = $(this).attr("data-filter");
        $grid.isotope({ filter: filterValue });
      });

      // change is-checked class on buttons
      $(".isotop-menu-wrapper").each(function (i, buttonGroup) {
        var $buttonGroup = $(buttonGroup);
        $buttonGroup.on("click", "li", function () {
          $buttonGroup.find(".is-checked").removeClass("is-checked");
          $(this).addClass("is-checked");
        });
      });
    }
  }); //End On Load Function
}
