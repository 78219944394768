import React from "react";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";

export default function Footer({ footer, globals }) {
  const { links = [] } = globals;
  const { links: footerLinks = [] } = footer;

  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const onSubmit = async (values) => {
    const hhEndpoint = `https://api.headlesshost.com/sites/${process.env.REACT_APP_HH_SITE_ID}/contact`;
    const data = {
      purpose: "Newsletter",
      source: "Marketing",
      ...values,
    };
    await postData(hhEndpoint, data);
  };

  return (
    <footer className="vcamp-footer-two dark-footer pt-150 lg-pt-100 mt-100">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-2 mb-40">
            <div className="logo">
              <Link to="/" state={{ page: "home" }}>
                <img src={footer.logo?.url} alt="" width="200" />
              </Link>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-sm-6 mb-40">
            <h5 className="title">Links</h5>
            <ul className="footer-list style-none">
              {links.map((l) => (
                <li key={l.identifier}>
                  {l.identifier?.startsWith("http") ? (
                    <a href={l.identifier}>{l.title}</a>
                  ) : (
                    <Link to={l.identifier} state={{ page: l.identifier }}>
                      {l.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-6 mb-40">
            <h5 className="title">Legal</h5>
            <ul className="footer-list style-none">
              {footerLinks.map((l) => (
                <li key={l.identifier}>
                  {l.identifier?.startsWith("http") ? (
                    <a href={l.identifier}>{l.title}</a>
                  ) : (
                    <Link to={l.identifier} state={{ page: l.identifier }}>
                      {l.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-4 col-md-6 mb-40">
            <div className="newsletter">
              <h5 className="title">Newsletter</h5>
              <p>
                Join the <span>creative</span> people getting our emails
              </p>

              <Form
                onSubmit={onSubmit}
                validate={(values) => {
                  const errors = {};
                  if (!values.email) {
                    errors.email = "Please provide a valid email address.";
                  }
                  return errors;
                }}
                render={({ handleSubmit, submitSucceeded, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    {submitSucceeded && (
                      <div className="alert alert-success" role="alert">
                        All done!
                      </div>
                    )}
                    {!submitSucceeded && (
                      <>
                        <Field name="email">
                          {({ input, meta }) => (
                            <>
                              <input type="email" {...input} placeholder="Email" />
                              {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
                            </>
                          )}
                        </Field>
                        <button className="dark-btn" type="submit" disabled={submitting}>
                          Sign Up
                        </button>
                      </>
                    )}
                  </form>
                )}
              />
              <div className="info">We only send interesting, relevant and infrequent emails.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="bottom-footer">
          <div className="row">
            <div className="col-lg-4 order-lg-1 mb-15"></div>

            <div className="col-lg-4 order-lg-2 mb-15">
              <p className="copyright text-center">Copyright @2023.</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
