import React from "react";

export default function Pricing({ section }) {
  const { pricing = [] } = section;
  const backgroundColours = ["#E2F2FD", "#f9d6f9", "#FFEBEB"];

  return (
    <div className="pricing-section-one mt-130 pt-130">
      <div className="container">
        <div className="row">
          <div className="col-xxl-9 col-xl-10 col-lg-8 col-md-10 m-auto">
            <div className="title-style-one text-center">
              <h2 className="title">
                {section.title} <span>{section.titleUnderline}</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="pricing-table-area-one mt-100">
          <div className="tab-content">
            <div className="tab-pane fade show active" id="month">
              <div className="row justify-content-center">
                {pricing.map((p, i) => {
                  const { features = [] } = p;
                  return (
                    <div className="col-lg-4 col-md-6" key={p.title}>
                      <div className={`pr-table-wrapper ${p.title === "Business plan" ? " active most-popular" : ""}`}>
                        <div className="pack-name">{p.title}</div>
                        <div className="pack-details">{p.subTitle}</div>
                        <div className="top-banner d-md-flex" style={{ background: backgroundColours[i] }}>
                          <>
                            {p.price > 0 ? (
                              <div className="price">
                                <sup>$</sup>
                                {p.price}
                              </div>
                            ) : (
                              <div className="price">Contact</div>
                            )}
                            <div>
                              <span>{p.priceHeading}</span>
                              <em>{p.priceSubHeading}</em>
                            </div>
                          </>
                        </div>
                        <ul className="pr-feature">
                          {features.map((f) => (
                            <li key={f}>{f}</li>
                          ))}
                        </ul>
                        <a href="https://go.headlesshost.com" className="trial-button">
                          Start 30 days free trial
                        </a>
                        <div className="trial-text">No card required, cancel any time</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
