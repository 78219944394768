import React, { useState } from "react";
import icon_41 from "../images/icon/icon_41.svg";
import VideoModal from "./VideoModal";
import ActionLink from "./ActionLink";

export default function VideoFeature({ section }) {
  const [showModal, setShowModal] = useState();

  const Words = () => (
    <div className="col-xxl-6 col-xl-7 col-lg-6">
      <div className="text-wrapper ps-xxl-5 pt-0 md-pb-40">
        <div className="title-style-one">
          <h2 className="title">
            {section.title} <span>{section.titleUnderline}</span> {section.titleTail}
          </h2>
        </div>
        <p className="meta-info-text text-lg"> {section.description}</p>
        <ActionLink className="theme-btn-two btn" onClick={() => setShowModal(true)}>
          {section.buttonText}
        </ActionLink>
      </div>
    </div>
  );

  const Image = () => (
    <div className="col-xxl-6 col-xl-5 col-lg-6">
      <div className="video-img-box">
        <img src={section.image.url} alt="" />
        <ActionLink className="fancybox video-icon d-flex align-items-center justify-content-center" onClick={() => setShowModal(true)}>
          <img src={icon_41} alt="" />
        </ActionLink>
      </div>
    </div>
  );

  return (
    <>
      {showModal && <VideoModal videoId={section.youtubeLink} close={() => setShowModal(false)} />}
      <div className="vcamp-text-block-one border-top mt-150 lg-mt-120 pt-120 pb-120 lg-pb-80">
        <div className="container">
          <div className="row align-items-center">
            {section.alignleft ? (
              <>
                <Image />
                <Words />
              </>
            ) : (
              <>
                <Words />
                <Image />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
