import React from "react";

export default function PortfolioSingle({ section = {} }) {
  return (
    <section className="wrapper">
      <div className="container">
        <div className="row">
          <div className="col-xxl-11 m-auto pt-4">
            <div className="border rounded-2 p-4">
              <img src={section.image.url} alt="" />
              <div className="caption tran3s d-flex justify-content-end flex-column">
                <h4 className="mt-4">{section.title}</h4>
                <h6>{section.paragraph}</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
