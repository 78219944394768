import React from "react";
import icon_89 from "../images/icon/icon_89.svg";
import icon_90 from "../images/icon/icon_90.svg";
import icon_91 from "../images/icon/icon_91.svg";
const images = [icon_89, icon_90, icon_91];

export default function Triplet({ section }) {
  return (
    <div className="vcamp-feature-section-eleven border-bottom mt-20 pb-100">
      <div className="container">
        <div className="row gx-xl-5 justify-content-center">
          {section.features.map((f, i) => (
            <div className="col-lg-4 col-sm-6 mt-50" key={i}>
              <div className="card-style-eleven text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={i <= images.length - 1 ? images[i] : images[0]} alt="" />
                </div>
                <h4>{f.heading}</h4>
                <p className="pe-xxl-4 ps-xxl-4">{f.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
