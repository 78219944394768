import React from "react";

export default function ColourBanner({ section }) {
  const { title, intro } = section;
  return (
    <div className="inside-hero-one hero-spacing">
      <div className="container">
        <h2 className="page-title font-recoleta">{title}</h2>
        <p>{intro}</p>
      </div>
    </div>
  );
}
