import React from "react";
import { Form, Field } from "react-final-form";

export default function BlogSidebar({ section = {} }) {
  const { title, information } = section;
  async function postData(url = "", data = {}) {
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify(data),
    });
    return response.json();
  }

  const onSubmit = async (values) => {
    const hhEndpoint = `https://api.headlesshost.com/sites/${process.env.REACT_APP_HH_SITE_ID}/contact`;
    const data = {
      purpose: "Newsletter",
      source: "Marketing",
      ...values,
    };
    await postData(hhEndpoint, data);
  };
  return (
    <div className="col-lg-4 col-md-8">
      <div className="blog-sidebar md-mt-80">
        <div className="blog-sidebar-category mb-80 md-mb-50">
          <h4 className="sidebar-title">{title}</h4>
          <p className="preWrap">{information}</p>
        </div>
        <div class="blog-sidebar-search mb-60">
          <h4 className="sidebar-title">Newsletter</h4>
          <p className="preWrap">Enter your email below to receive our newsletter</p>
          <Form
            onSubmit={onSubmit}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Please provide a valid email address.";
              }
              return errors;
            }}
            render={({ handleSubmit, submitSucceeded, submitting }) => (
              <form onSubmit={handleSubmit}>
                {submitSucceeded && (
                  <div className="alert alert-success" role="alert">
                    All done!
                  </div>
                )}
                {!submitSucceeded && (
                  <>
                    <Field name="email">
                      {({ input, meta }) => (
                        <>
                          <input type="email" {...input} placeholder="Email" />
                          {meta.touched && meta.error && <div className="invalid-feedback">{meta.error}</div>}
                        </>
                      )}
                    </Field>

                    <button type="submit" disabled={submitting}>
                      <i class="bi bi-upload"></i>
                    </button>
                  </>
                )}
              </form>
            )}
          />
        </div>
      </div>
    </div>
  );
}
