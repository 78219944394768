import React from "react";

export default function Textblock({ section }) {
  const { title, paragraph } = section;

  return (
    <section className="wrapper">
      <div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h4>{title}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <article>
                <p className="preWrap" style={{ fontSize: ".9rem" }}>
                  {paragraph}
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
