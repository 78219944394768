import React from "react";

export default function BlockBannerSub({ section }) {
  const { title, subTitle, description } = section;

  return (
    <section className="wrapper mt-130 pt-100">
      <div className="contact-section-four">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 col-lg-8 col-md-10 m-auto">
              <div className="title-style-one text-center">
                <h2 className="title">
                  {title} <span>{subTitle}</span>
                </h2>
                <p className="text-lg pt-30 lg-pt-20 preWrap"> {description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
