import React from "react";
import { Link } from "react-router-dom";

export default function BlogBanner({ section }) {
  const { category, title, date, author } = section;
  return (
    <div className="inside-hero-six hero-spacing pt-4">
      <div className="bg-container mt-3">
        <div className="container">
          <p className="blog-pubish-date">
            {category} . {new Date(date).toDateString().substring(4)} . By <b>{author}</b>
          </p>
          <div className="row">
            <div className="col-xxl-8 col-xl-10 col-lg-7">
              <h2 className="blog-heading-one">{title}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <nav className="blog-breadcrumb mt-40 lg-mt-20">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/news"}>News</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {title}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  );
}
