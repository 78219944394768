import React from "react";

export default function CallToActionPlain({ section }) {
  return (
    <div className="fancy-banner-seven mb-100 lg-mb-60" data-aos="fade-up">
      <div className="container">
        <div className="inner-content position-relative">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-9 text-center text-lg-start">
              <h3 className="font-recoleta">{section.title}</h3>
              <p></p>
            </div>
            <div className="col-xl-4 col-lg-3 text-center text-lg-end">
              <a href="contactV1.html" className="theme-btn-four ripple-btn">
                {section.buttonText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
