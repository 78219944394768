import React from "react";
import ReactMarkdown from "react-markdown";

export default function BlogText({ section }) {
  return (
    <p className="mb-80">
      <ReactMarkdown linkTarget="_blank">{section.markdown}</ReactMarkdown>
    </p>
  );
}
