import React from "react";
import icon_89 from "../images/icon/icon_89.svg";
import icon_90 from "../images/icon/icon_90.svg";
import icon_91 from "../images/icon/icon_91.svg";
const images = [icon_89, icon_90, icon_91];

export default function FeatureBlock({ section }) {
  return (
    <div className="vcamp-feature-section-eleven border-bottom pt-130 mt-130 pb-100">
      <div className="container">
        <div className="row">
          <div className="col-xxl-8 col-xl-10 col-lg-7 col-md-10 m-auto">
            <div className="title-style-nine text-center">
              <h2 className="title">{section.title}</h2>
              <p className="text-lg pt-30 lg-pt-20">{section.description}</p>
            </div>
          </div>
        </div>

        <div className="row gx-xl-5 justify-content-center">
          {section.features.map((f, i) => (
            <div className="col-lg-4 col-sm-6 mt-50" key={i}>
              <div className="card-style-eleven text-center">
                <div className="icon d-flex align-items-center justify-content-center">
                  <img src={i <= images.length - 1 ? images[i] : images[0]} alt="" />
                </div>
                <h4>{f.heading}</h4>
                <p className="pe-xxl-4 ps-xxl-4">{f.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
