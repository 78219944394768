import React from "react";

export default function SimpleImage({ section }) {
  const { image = {} } = section;
  return (
    <>
      <div className="blog-section-two pt-50">
        <div className="container">
          <div className="row">
            <div className="col-xxl-11 m-auto">
              <div className="row gx-xl-5 rounded ">
                <img src={image.url} className="rounded border py-3" alt={section.title} style={{ maxWidth: `${image.width}px` }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
