import React from "react";

export default function BlockBanner({ section }) {
  return (
    <div className="hero-spacing pt-100">
      <div className="container">
        <div className="row">
          <div className="col-xxl-9 col-xl-10 col-lg-8 col-md-10 m-auto">
            <div className="title-style-one text-center">
              <h2 className="title">
                {section.title} <span>{section.titleUnderline}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
