import React from "react";

export default function Services() {
  return (
    <div className="vcamp-feature-section-three mt-100 lg-mt-120">
      <div className="container">
        <div className="row align-items-center mb-40 md-mb-20">
          <div className="col-lg-5 col-md-6 col-sm-8" data-aos="fade-right">
            <div className="title-style-five">
              <h2 className="title">Our included service.</h2>
            </div>
          </div>
          <div className="col-md-6 col-sm-4 me-auto text-sm-end text-start" data-aos="fade-left">
            <a href="serviceV1.html" className="theme-btn-five position-relative xs-mt-30">
              See all Services
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-sm-6" data-aos="fade-up">
            <div className="card-style-three mt-50">
              <div className="icon d-flex align-items-center justify-content-center">
                <img src="images/icon/icon_24.svg" alt="" className="tran3s" />
              </div>
              <h4 className="title">Core WordPress</h4>
              <ul className="style-none">
                <li>Personalization</li>
                <li>Third-Party Integrations</li>
                <li>First-Party Analytics</li>
                <li>Hosting Setup</li>
                <li>Securty</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-delay="100">
            <div className="card-style-three mt-50">
              <div className="icon d-flex align-items-center justify-content-center">
                <img src="images/icon/icon_25.svg" alt="" className="tran3s" />
              </div>
              <h4 className="title">Platform Audit</h4>
              <ul className="style-none">
                <li>Full Consultation</li>
                <li>Code Review</li>
                <li>Staff Augmentation</li>
                <li>Support</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-delay="200">
            <div className="card-style-three mt-50">
              <div className="icon d-flex align-items-center justify-content-center">
                <img src="images/icon/icon_26.svg" alt="" className="tran3s" />
              </div>
              <h4 className="title">e-Commerce</h4>
              <ul className="style-none">
                <li>Custom Checkout Flow</li>
                <li>Customer identity</li>
                <li>Conversion Rate Optimization</li>
                <li>Security Checkup</li>
                <li>Data enrty</li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6" data-aos="fade-up" data-aos-delay="300">
            <div className="card-style-three mt-50">
              <div className="icon d-flex align-items-center justify-content-center">
                <img src="images/icon/icon_27.svg" alt="" className="tran3s" />
              </div>
              <h4 className="title">Marketing</h4>
              <ul className="style-none">
                <li>Microsites</li>
                <li>Marketing Automation</li>
                <li>SEO & SMM</li>
                <li>Lead Generation</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
