import React from "react";
import icon_33 from "../images/icon/icon_33.svg";
import ReactMarkdown from "react-markdown";
export default function Split({ section = {} }) {
  const Words = () => (
    <div style={{ maxWidth: "900px" }}>
      <div className="icon d-flex align-items-center justify-content-center">
        <img src={icon_33} alt="" />
      </div>
      <div className="title-style-one">
        <h2 className="title">
          {section.title} <span>{section.underline}</span> {section.tail}
        </h2>
      </div>
      <div className="mt-3">
        <ReactMarkdown linkTarget="_blank">{section.markdown}</ReactMarkdown>
      </div>
      {section.link && (
        <a href={section.link} target="_blank" rel="noreferrer">
          {section.link}
        </a>
      )}
    </div>
  );

  const Image = () => (
    <div className="video-img-box" style={{ maxWidth: "300px" }}>
      <img src={section.image.url} alt="" />
    </div>
  );

  return (
    <>
      <div className="vcamp-text-block-one mt-50 pt-50 md-mt-100 md-pt-100 lg-mt-150 lg-pt-150">
        <div className="container">
          {section.alignleft ? (
            <div className="d-flex flex-wrap">
              <div className="pe-4">
                <Image />
              </div>
              <Words />
            </div>
          ) : (
            <div className="d-flex flex-wrap">
              <div className="pe-4">
                <Words />
              </div>
              <Image />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
