import React from "react";

export default function BlogImage({ section }) {
  const { image } = section;
  if (!image) return null;
  return (
    <div className="border p-2 rounded mb-20">
      <img src={image.url} alt="" className="image-meta w-100" />
    </div>
  );
}
