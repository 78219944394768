import React from "react";

export default function HeroBanner({ section }) {
  return (
    <div className="hero-banner-five mt-200 md-mt-150">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xxl-5 col-lg-6 order-lg-last">
            <h1 className="hero-heading mt-0">{section.title}</h1>
            <p className="hero-sub-heading text-lg">{section.description}</p>
            <div className="container">
              <div className="d-flex flex-wrap">
                <div className="me-4">
                  <p className="hero-sub-heading text-lg"> 30 day free trial</p>
                </div>
                <div className="hero-btn">
                  <a href="https://go.headlesshost.com/pub/register" className="theme-btn-six ripple-btn sm-mt-30 sm-mb-30">
                    Get started!
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xxl-7 col-lg-6">{section.animated ? <img src={section.animated.url} alt="Headlesshost" className="rounded-5" style={{ width: "700px" }} /> : <img src={section.image.url} alt="Headlesshost" className="rounded-5" style={{ width: "700px" }} />}</div>
        </div>
      </div>
    </div>
  );
}
