export default function CallToAction({ section }) {
  const { title, paragraph, buttonText, link } = section;
  return (
    <section className="wrapper">
      <div className="border-top mt-150 pt-150 md-mt-100 md-pt-100 lg-mt-150 lg-pt-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-9 col-xl-10 col-lg-8 col-md-10 m-auto">
              <div className="title-style-one text-center">
                <h2 className="title">{title}</h2>
                <p className="text-lg pt-30 lg-pt-20">{paragraph}</p>
              </div>
              <div className="text-center pt-4">
                <div className="hero-btn">
                  <a href={link} className="theme-btn-six ripple-btn sm-mt-30 sm-mb-30">
                    {buttonText}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
