import React from "react";
import { Link } from "react-router-dom";
import icon_01 from "../images/icon/icon_01.svg";

export default function Header({ header, globals, pageIdentifier }) {
  const { logo = {} } = header;
  const { links = [] } = globals;
  return (
    <header className="theme-main-menu sticky-menu theme-menu-one">
      <div className="inner-content">
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to={"/"}>
              <img src={logo.url} alt="" width="260" />
            </Link>
          </div>
          <nav className="navbar navbar-expand-lg">
            <button className="navbar-toggler d-block d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <div className="mb-4 d-lg-none text-center">
                <div className="hero-btn">
                  <a href="https://go.headlesshost.com" className="theme-btn-six ripple-btn">
                    Login
                  </a>
                </div>
              </div>

              <ul className="navbar-nav">
                <li className="d-block d-lg-none">
                  <div className="logo mb-4">
                    <Link to="/">
                      <img src={logo.url} alt="" width="127" />
                    </Link>
                  </div>
                </li>
                {links.map((l) => (
                  <li className={`nav-item ${pageIdentifier === l.identifier ? "active" : ""}`} key={l.identifier}>
                    {l.identifier?.startsWith("http") ? (
                      <a href={l.identifier} className="nav-link">
                        <span data-bs-toggle="collapse" data-bs-target="#navbarNav">
                          {l.title}
                        </span>
                      </a>
                    ) : (
                      <>
                        {l.children && l.children.length > 0 ? (
                          <li className="nav-item dropdown">
                            <button className="nav-link dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                              {l.title}
                            </button>
                            <ul className="dropdown-menu">
                              {l.children.map((c) => (
                                <li key={c.identifier}>
                                  {c.identifier?.startsWith("http") ? (
                                    <a href={c.identifier} className="dropdown-item">
                                      <span>{c.title}</span>
                                    </a>
                                  ) : (
                                    <Link to={c.identifier} state={{ page: c.identifier }} className="dropdown-item">
                                      <span data-bs-toggle="collapse" data-bs-target="#navbarNav">
                                        {c.title}
                                      </span>
                                    </Link>
                                  )}
                                </li>
                              ))}
                            </ul>
                          </li>
                        ) : (
                          <Link to={l.identifier} state={{ page: l.identifier }} className="nav-link">
                            <span data-bs-toggle="collapse" data-bs-target="#navbarNav">
                              {l.title}
                            </span>
                          </Link>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
              <div className="mobile-content d-block d-lg-none mt-5">
                <hr />
                <div className="address-block">
                  <h4 className="title">Our Location</h4>
                  <p>Wellington, New Zealand</p>
                  <p>
                    Urgent issue? email us at <br />
                    <br />
                    <a href="mailto:info@headlesshost.com">info@headlesshost.com</a>
                  </p>
                </div>
              </div>
            </div>
          </nav>
          <div className="right-widget">
            <a href="https://go.headlesshost.com" className="d-flex align-items-center login-btn">
              <img src={icon_01} alt="" />
              <span>login</span>
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
