import Header from "./Components/Header";
import Footer from "./Components/Footer";
import React, { useEffect } from "react";
import Preloader from "./Components/Preloader";
import AOS from "aos";
import "aos/dist/aos.css";
import * as theme from "./theme";
import Sections from "./Components/Sections";
import io from "socket.io-client";
import { Routes, Route, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Guide from "./Components/Guide";
import News from "./Components/News";
import NewsItem from "./Components/NewsItem";

const isProd = process.env.REACT_APP_IS_PROD === "true";
const socket = !isProd && io.connect("https://api.headlesshost.com");

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const [site, setSite] = React.useState();
  const location = useLocation();
  const pageIdentifier = location.pathname.substring(1);
  const query = useQuery();

  const siteInstance = isProd ? "" : query.get("instanceId") ? "/instance/" + query.get("instanceId") : "/draft";
  const hhEndpoint = `https://api.headlesshost.com/sites/${process.env.REACT_APP_HH_SITE_ID}${siteInstance}`;

  useEffect(() => {
    AOS.init();
    theme.init();
    if (!isProd) {
      const contentSiteId = process.env.REACT_APP_HH_SITE_ID;
      const onSiteUpdated = async () => {
        try {
          const res = await fetch(hhEndpoint);
          const updatedSite = await res.json();
          setSite(updatedSite);
        } catch (error) {
          console.log(error);
        }
      };

      socket.emit("ContentSite-Join", contentSiteId);
      socket.on("StageUpdated", onSiteUpdated);
      return () => {
        socket.emit("ContentSite-Leave", contentSiteId);
        socket.off(contentSiteId, onSiteUpdated);
      };
    }
  }, [hhEndpoint]);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
    });
  }, [pageIdentifier]);

  useEffect(() => {
    async function fetchSite() {
      try {
        const res = await fetch(hhEndpoint);
        return await res.json();
      } catch (error) {
        console.log(error);
      }
    }
    if (!site) {
      fetchSite()
        .then((resp) => {
          setSite(resp);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [hhEndpoint, site]);

  if (!site) return <Preloader />;

  const { content = {} } = site;
  const { pages = [], header = {}, footer = {}, globals = {} } = content;
  const page = pages.find((p) => p.identifier === pageIdentifier) || pages[0] || {};
  const { sections = [] } = page;
  const headervm = { ...header, globals };

  return (
    <>
      <Header header={headervm} globals={globals} pageIdentifier={pageIdentifier} />

      <Routes>
        <Route path="guide" element={<Guide />} />
        <Route path="news" element={<News sections={sections} globals={globals} />} />
        <Route path="news/:id" element={<NewsItem sections={sections} globals={globals} />} />
        <Route path="*" element={<Sections sections={sections} globals={globals} />} />
      </Routes>

      <Footer globals={globals} footer={footer} />
      <button
        className="scroll-top"
        onClick={() =>
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          })
        }
      >
        <i className="bi bi-arrow-up-short"></i>
      </button>
    </>
  );
}

export default App;
